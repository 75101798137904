import { FunctionComponent, useContext } from 'react';
import { CourseSearchContext } from '../CourseSearchContext';
import { NoCoursesAlert } from './NoCoursesAlert';
import { CourseGrid } from './Grid';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { PublicEducator } from '@cademy-package/sdk-component-types';
import Spinner from '../../Spinner';
import { DatedRows } from './DatedRows';
import { Rows } from './Rows';

type CourseResultsProps = {
    educator?: PublicEducator;
    displayAs: 'grid' | 'rows' | 'datedRows';
};
export const CourseResults: FunctionComponent<CourseResultsProps> = ({ educator, displayAs }) => {
    const { courses, searchTerm, filters, clearFilters, clearSearchTerm, isLoading } =
        useContext(CourseSearchContext);

    if (!courses.length) {
        return (
            <NoCoursesAlert
                search={searchTerm}
                filters={filters}
                clearSearch={clearSearchTerm}
                clearFilters={clearFilters}
                educator={educator}
            />
        );
    }

    switch (displayAs) {
        case 'grid':
            return (
                <div className={classNames({ [styles.isLoading]: isLoading })}>
                    <CourseGrid
                        courses={courses}
                        hidePricing={educator?.feature_flags?.hide_pricing}
                    />
                </div>
            );
        case 'rows':
            return (
                <div className={classNames({ [styles.isLoading]: isLoading })}>
                    <Rows
                        courses={courses}
                        showPricing={educator?.feature_flags?.hide_pricing !== false}
                    />
                </div>
            );
        case 'datedRows':
            return (
                <div className={classNames({ [styles.isLoading]: isLoading })}>
                    <DatedRows
                        courses={courses}
                        showPricing={educator?.feature_flags?.hide_pricing !== false}
                    />
                </div>
            );
    }
};

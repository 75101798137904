import { FunctionComponent, useMemo } from 'react';
import { SearchIndexCourseResult } from '../../../../services/search/searchEducator';

import styles from './styles.module.scss';
import { CourseRow } from '../../../CourseRow';

type DatedRowsProps = {
    courses: SearchIndexCourseResult['results'];
    showPricing?: boolean;
};

export const DatedRows: FunctionComponent<DatedRowsProps> = ({ courses, showPricing = true }) => {
    const coursesOrOccurrences = useMemo(() => {
        return courses
            .flatMap((course) => {
                if (course.occurrences && course.occurrences.length) {
                    return course.occurrences;
                }
                return [course];
            })
            .toSorted((courseOrOccurrence1, courseOrOccurrence2) => {
                return (
                    (courseOrOccurrence1.start_datetime || Infinity) -
                    (courseOrOccurrence2.start_datetime || Infinity)
                );
            });
    }, [courses]);

    return (
        <ul className={styles.courseRows}>
            {coursesOrOccurrences.map((courseOrOccurrence) => {
                return (
                    <li key={courseOrOccurrence._id}>
                        <CourseRow course={courseOrOccurrence} showPricing={showPricing} />
                    </li>
                );
            })}
        </ul>
    );
};

import { FunctionComponent, useContext, useMemo } from 'react';
import { SearchIndexCourseResult } from '../../../../services/search/searchEducator';

import styles from './styles.module.scss';
import CourseCard from '../../../CourseCard';
import { useSavedCoursesState } from '../../../../hooks/useSavedCoursesState';

type CourseGridProps = {
    courses: SearchIndexCourseResult['results'];
    hidePricing?: boolean;
};

export const CourseGrid: FunctionComponent<CourseGridProps> = ({
    courses,
    hidePricing = false,
}) => {
    const courseIds = useMemo(() => courses.map((course) => course._id), [courses]);
    const { data: savedCoursesState } = useSavedCoursesState({ courseIds });

    return (
        <ul className={styles.courseGrid}>
            {courses.map((course) => {
                return (
                    <li key={course._id}>
                        <CourseCard
                            course={course}
                            hidePricing={hidePricing}
                            saved={savedCoursesState[course._id]}
                        />
                    </li>
                );
            })}
        </ul>
    );
};

import { CademyError } from '@shared/domain-shared';
import { LegacySearchIndexCourse, SearchIndexCourseFilters } from '@shared/types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GeneralSearchError } from '../errors';
import { LocationFilter, locationFilterToGeoFilter } from '../locationFilter';
import { SearchIndexSortBy } from '../shared';
import { CourseSearch, SearchIndexCourseResult } from './index';
import Analytics from '../../Analytics';
import { BoostChangeEmitter } from '../../../pages_components/courses/DebugSearchWeights';
import { useDebouncedValue } from '../../../hooks/useDebouncedValue';

export type useCourseSearchProps = {
    filters: WebCourseSearchFilters;
    search: string;
    sort?: SearchIndexSortBy;
    perPage: number;
    initialResults?: {
        results: SearchIndexCourseResult['results'];
        page: number;
        nextPage: number;
        totalResults: number;
    };
    initialError?: CademyError | null;
    debug?: boolean;
};

export type useCourseSearchResult = {
    results: SearchIndexCourseResult['results'];
    error: CademyError | null;
    isLoading: boolean;
    page: number;
    nextPage: number;
    totalResults: number;
    hasMore: boolean;
    loadMore: () => void;
    setPage: (page: number) => void;
};

export type WebCourseSearchFilters = Omit<SearchIndexCourseFilters, 'geo_coordinates'> & {
    location?: LocationFilter;
};

export const webFiltersToSearchFilters = async (
    webFilters: WebCourseSearchFilters
): Promise<SearchIndexCourseFilters> => {
    const geo_coordinates = webFilters.location
        ? await locationFilterToGeoFilter(webFilters.location)
        : undefined;
    return {
        category_slug: webFilters.category_slug,
        delivery: webFilters.delivery,
        educator_ref: webFilters.educator_ref,
        list_refs: webFilters.list_refs,
        marketplace_tag_slug: webFilters.marketplace_tag_slug,
        minimum_rating: webFilters.minimum_rating,
        context: webFilters.context,
        range: webFilters.range,
        geo_coordinates,
    };
};

export const useCourseSearch = ({
    filters,
    search,
    sort,
    perPage,
    initialResults,
    initialError,
    debug,
}: useCourseSearchProps): useCourseSearchResult => {
    const shouldFetchInitialResults = useRef<boolean>(initialResults ? false : true);
    const [results, setResults] = useState<Array<LegacySearchIndexCourse>>(
        initialResults?.results || []
    );
    const [isLoading, setIsLoading] = useState(false);
    const isLoadingRef = useRef<boolean>(false);
    const [page, setPageInternal] = useState<number>(initialResults?.page || 1);
    const [nextPage, setNextPage] = useState<number>(initialResults?.nextPage || 1);
    const [totalResults, setTotalResults] = useState<number>(initialResults?.totalResults || 0);
    const [hasMore, setHasMore] = useState(
        initialResults?.page !== initialResults?.nextPage || false
    );
    const [error, setError] = useState<null | CademyError>(initialError || null);

    const appliedFiltersRef = useRef<WebCourseSearchFilters>(filters);
    const appliedSearchRef = useRef<string>(search);
    const appliedSortRef = useRef<SearchIndexSortBy | undefined>(sort);
    const activeFilters = useDebouncedValue(isLoading ? appliedFiltersRef.current : filters, 300);
    const activeSearch = useDebouncedValue(isLoading ? appliedSearchRef.current : search, 300);
    const activeSort = useDebouncedValue(isLoading ? appliedSortRef.current : sort, 300);

    const settled = useMemo(() => {
        return (
            JSON.stringify(activeFilters) === JSON.stringify(appliedFiltersRef.current) &&
            activeSearch === appliedSearchRef.current &&
            activeSort === appliedSortRef.current
        );
    }, [activeFilters, activeSearch, activeSort]);

    const getResults = useCallback(
        async (
            search: string,
            filters: WebCourseSearchFilters,
            additive: boolean,
            page: number,
            sort: SearchIndexSortBy | undefined
        ) => {
            if (isLoadingRef.current === true) {
                return;
            }
            appliedFiltersRef.current = filters;
            appliedSearchRef.current = search;
            appliedSortRef.current = sort;
            isLoadingRef.current = true;
            setIsLoading(true);
            try {
                const convertedFilters = await webFiltersToSearchFilters(filters);
                await Analytics.record.event('Course Search', { search, filters, page, sort });
                const {
                    results,
                    page: resultPage,
                    totalResults,
                    nextPage,
                } = await CourseSearch(search, convertedFilters, page, perPage, sort, debug);
                setPageInternal(resultPage);
                setTotalResults(totalResults);
                setNextPage(nextPage);
                setHasMore(resultPage !== nextPage);
                setError(null);
                setResults((current) => {
                    if (additive === false) {
                        return results;
                    }
                    return [...current, ...results];
                });
            } catch (error) {
                if (error instanceof CademyError) {
                    setError(error);
                } else {
                    console.error(error);
                    setError(new GeneralSearchError());
                }
            }
            isLoadingRef.current = false;
            setIsLoading(false);
        },
        [debug, perPage]
    );

    const loadMore = useCallback(async () => {
        return getResults(activeSearch, activeFilters, true, page + 1, activeSort);
    }, [getResults, activeSearch, activeFilters, activeSort, page]);

    useEffect(() => {
        if (shouldFetchInitialResults.current === true) {
            shouldFetchInitialResults.current = false;
            getResults(activeSearch, activeFilters, false, 1, activeSort);
            return;
        }
        if (settled === true) {
            return;
        }
        getResults(activeSearch, activeFilters, false, 1, activeSort);
    }, [getResults, activeSearch, activeFilters, activeSort, settled]);

    useEffect(() => {
        const handler = () => getResults(activeSearch, activeFilters, false, page, activeSort);
        BoostChangeEmitter.addListener('change', handler);
        return () => {
            BoostChangeEmitter.removeListener('change', handler);
        };
    }, [getResults, activeSearch, activeFilters, page, activeSort]);

    const setPage = useCallback(
        async (page: number) => {
            return getResults(search, filters, false, page, sort);
        },
        [getResults, search, filters, sort]
    );

    return {
        results,
        isLoading,
        page,
        nextPage,
        totalResults,
        hasMore,
        error,
        loadMore,
        setPage,
    };
};

export default useCourseSearch;
